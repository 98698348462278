import { store } from '@store/storeConfig/store'
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedPaymentList: localStorage.getItem("selectedPaymentList") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedPaymentList")),
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  getError: false,
  errorMsg: 'Error occured!!',
  otpSuccess: false,
  setNewPassword: false,
  found_results: '',
  entries_per_page: '',
  total_results: '',
  fetching: false,
  updatePagination: 0

}




const paymentList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PAYMENTLIST':
      return { ...state, allData: action.data, customTotal: action.total, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'GET_ADMIN_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'EDITPAYMENTLIST':

      return { ...state, selectedPaymentList: action.data }
    case 'DELETE_PAYMENTLIST':
      return { ...state, updatePagination: action.data }
    case 'GET_ERROR':
      return { ...state, getError: true, errorMsg: action.error }
    case 'GET_ERROR_NOTI_CLOSE':
      return { ...state, getError: false }
    default:
      return { ...state }
  }
}
export default paymentList
