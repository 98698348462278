import { store } from '@store/storeConfig/store'
// ** Initial State
const initialState = {
  allData: [],
  total: 1,
  selectedMerchant: localStorage.getItem("selectedMerchant") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedMerchant")),
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  updatePagination: 0,
  selectedMerchantForKey: null,
  getError: false,
  errorMsg: 'Error occured!!',
}




const merchantList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_MERCHANTS':
      return { ...state, allData: action.data, customTotal: action.total, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'EDITMERCHANT':
      return { ...state, selectedMerchant: action.data }
    case 'EDITMERCHANTFORkEY':
      return { ...state, selectedMerchantForKey: action.data }
    case 'CLOSE_MODEL':
      return {...state, selectedMerchantForKey: null}
    case 'GET_ERROR':
          return { ...state, getError: true, errorMsg: action.error }
    case 'GET_ERROR_NOTI_CLOSE':
      return { ...state, getError: false, errorMsg: '' }
    case 'DELETE_MERCHANT':
      return { ...state, updatePagination: action.data }
    default:
      return { ...state }
  }
}
export default merchantList
