// ** Initial State
const initialState = {
  allData: [],
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  currentPage: '',
  selectedJr: localStorage.getItem("selectedJr") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedJr")),

}



const jr = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_JR':
      return { ...state, allData: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results, currentPage: action?.currentPage }

    case 'DELETE_BOOK':

      return { ...state, allData: action.data }
    case 'EDITJR':
      return { ...state, selectedJr: action.data }
    case 'BOOK_PAGE_CHANGE':
      return { ...state, allData: [], addonBooks: [] }

    default:
      return { ...state }
  }
}
export default jr
