import { store } from '@store/storeConfig/store'
// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedPaymentType: localStorage.getItem("selectedPaymentType") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedPaymentType")),
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  getError: false,
  errorMsg: 'Error occured!!',
  otpSuccess: false,
  setNewPassword: false,
  found_results: '',
  entries_per_page: '',
  total_results: '',
  fetching: false,
  modalOpen: false,
  updatePaymentType: false,
  updatePagination: 0

}




const paymentType = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PAYMENTTYPES':
      return { ...state, allData: action.data, customTotal: action.total, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }

    case 'CLOSE_MODEL':
      return { ...state, modalOpen: false, updatePaymentType: false }
    case 'OPEN_MODEL':
      return { ...state, modalOpen: true }
    case 'DELETE_PAYMENTTYPE':
      return { ...state, updatePagination: action.data }
    case 'EDITPAYMENTTYPE':
      return { ...state, selectedPaymentType: action.data, updatePaymentType: true }
    case 'GET_ERROR':
      return { ...state, getError: true, errorMsg: action.error }

    default:
      return { ...state }
  }
}
export default paymentType
