// ** Initial State
const initialState = {
  allHistories: [],
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: ''

}



const auditLog = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_HISTORIES':
      return { ...state, allHistories: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'DELETE_HISTORY':

      return { ...state, allHistories: action.data }
    case 'CLEAR_HISTORIES':
      return { ...state, allHistories: [] }
    default:
      return { ...state }
  }
}
export default auditLog
