// ** Initial State
const initialState = {
  allData: [],
  allVideos: [],
  data: [],
  total: 1,
  params: {},
  currentUser: null,
  selectedUser: null,
  selectedVideo: localStorage.getItem("selectedVideo") === 'undefined' ? null : JSON.parse(localStorage.getItem("selectedVideo")),
  bookEdit: false,
  customPagination: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: ''
}



const videos = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_VIDEOS':

      return { ...state, allVideos: action.data, customPagination: action.customTotal, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'GET_BOOK_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USER':

      return { ...state, selectedUser: action.selectedUser }
    case 'ADD_USER':
      return { ...state, allData: action.data }
    case 'DELETE_VIDEO':

      return { ...state, allVideos: action.data }
    case 'EDITVIDEO':
      return { ...state, selectedVideo: action.data }
    case 'VIDEO_PAGE_CHANGE':
      return { ...state, allVideos: [] }
    default:
      return { ...state }
  }
}
export default videos
