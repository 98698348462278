import { store } from '@store/storeConfig/store'
// ** Initial State
const initialState = {
  allData: [],
  total: 1,
  selectedMerchantMDRS: [],
  customTotal: null,
  token: JSON.parse(localStorage.getItem("accessToken")),
  found_results: '',
  entries_per_page: '',
  total_results: '',
  updatePagination: 0,
  merchantId: ''
}




const mdrRange = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_PAYMENTLISTS':
      return { ...state, allData: action.data, customTotal: action.total, entries_per_page: action.entries_per_page, found_results: action?.found_results, total_results: action?.total_results }
    case 'MERCHANTANDMDRRANGE':
      return { ...state, selectedMerchantMDRS: action.data, merchantId: action?.merchantId }
    case 'REMOVEALLPAYMENTLIST':
      return { ...state, allData: [] }
    default:
      return { ...state }
  }
}
export default mdrRange
