import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllData } from '../socket.io/store/action';

const firebaseConfig = {
    apiKey: "AIzaSyBSQwRtXiSGxDKKMsSww4eCAD5biYZpmmM",
    authDomain: "aptm-e363c.firebaseapp.com",
    databaseURL: "https://aptm-e363c-default-rtdb.firebaseio.com",
    projectId: "aptm-e363c",
    storageBucket: "aptm-e363c.appspot.com",
    messagingSenderId: "779487278709",
    appId: "1:779487278709:web:4b41bfb0f7871af324aa3c",
    measurementId: "G-Y9MV1N77GW"
  };
firebase.initializeApp(firebaseConfig);

export default function EventFirebase() {
  const dispatch = useDispatch();

  useEffect(() => {
    const connectedRef = firebase.database().ref('.info/connected');
    const dbRef = firebase.database().ref('notifications');
    
    connectedRef.on('value', (snapshot) => {
      const isConnected = snapshot.val();
      console.log('Firebase Realtime Database connection state:', isConnected);
      if (isConnected) {
        // dbRef.once('value', (snapshot) => {
        //   const usersData = snapshot.val();
        //   console.log('Users data:', usersData);
        //   dispatch({
        //     type: 'GET_ALL_SOCKET_NOTIS',
        //     data: usersData
        //   })
        // });
        dbRef.on('child_added', (snapshot) => {
              console.log(snapshot)
              // Retrieve the user data from the child snapshot
              const data = { id: snapshot.key, ...snapshot.val() };
              console.log("hello data", data)
              // console.log('databaseData', data)
              // Update the state with the new user data
              // setUsers((prevUsers) => [...prevUsers, users]);
              dispatch({
                type: 'ADD_NEW_NOTIS',
                data: data
              })
            });
      }
    });
  },[])

  // useEffect(() => {
  //     dispatch(getAllData())
  // },[])

  return (
    <div></div>
  );
}
